<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    {{ inventoryItemStockId ? 'Edit inventory item stock' : 'Add inventory item stock' }}
                                </h4>
                                <router-link :to="{ name: 'dashboard' }">
                                Dashboard \
                                </router-link>
                                <router-link :to="{name: 'inventory-item-stock'}">
                                Inventory item stock
                                </router-link>

                            </div>
                        </div>

                        <div class="row">
                            <v-col cols="12" md="12">
                                <v-autocomplete
                                    :items="inventoryItems"
                                    outlined 
                                    dense
                                    :error="$v.inventoryItemStock.inventory_item_id.$error"
                                    v-model="inventoryItemStock.inventory_item_id"
                                    item-text="title"
                                    item-value="id"
                                >
                                    <template v-slot:label>
                                        Inventory item <span class="text-danger">*</span>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="$v.inventoryItemStock.inventory_item_id.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.inventory_item_id">{{ errors.inventory_item_id[0] }}</div>
                            </v-col>

                            <v-col cols="12" md="6" class="mt-3">
                                <v-text-field 
                                    outlined 
                                    type="number"
                                    dense 
                                    :error="$v.inventoryItemStock.qty.$error"
                                    v-model="inventoryItemStock.qty"
                                >
                                    <template v-slot:label>Quantity <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.inventoryItemStock.qty.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.qty">{{ errors.qty[0] }}</div>
                                <div class="text-danger" v-if="errors.qty">{{ errors.qty[1] }}</div>
                                
                            </v-col>

                            <v-col cols="12" md="6" class="mt-3">
                                <v-text-field 
                                    outlined 
                                    type="number"
                                    dense 
                                    :error="$v.inventoryItemStock.price.$error"
                                    v-model="inventoryItemStock.price"
                                >
                                    <template v-slot:label>Price <span class="text-danger">*</span></template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.inventoryItemStock.price.$error">This information is required</span>
                                <div class="text-danger" v-if="errors.price">{{ errors.price[0] }}</div>
                                <div class="text-danger" v-if="errors.price">{{ errors.price[1] }}</div>
                            </v-col>

                            <v-col cols="12" md="10" class="mt-3">
                                <v-file-input
                                    outlined dense
                                    v-model="inventoryItemStock.file"
                                    @change="previewReceipt"
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-file"
                                    :error="receiptImageNotFound"
                                    accept="image/*"
                                >
                                    <template v-slot:label>
                                        Receipt image
                                    </template>
                                </v-file-input>
                                <span class="text-danger" v-if="receiptImageNotFound">This information is required</span>
                                <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>
                            </v-col>
                            
                            <v-col cols="12" md="2">
                                <v-img
                                    v-if="receiptImagePath"
                                    :lazy-src="receiptImagePath"
                                    height="100"
                                    width="120"
                                    class="img-thumbnail"
                                    :src="receiptImagePath"
                                >
                                    <v-btn 
                                        @click="deleteReceiptImage()" 
                                        icon small 
                                        style="margin-left: 89px; padding-bottom: 11px" 
                                        v-if="receiptImagePath"
                                    >
                                        <v-icon color="red">
                                            fas fa-times
                                        </v-icon>
                                    </v-btn>
                                </v-img>
                            </v-col>

                            <v-col cols="12" class="mt-3">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="menu"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        append-icon="mdi-calendar"
                                        clearable
                                        dense
                                        outlined
                                        readonly 
                                        :error="$v.inventoryItemStock.purchased_date.$error"
                                        v-bind="attrs"
                                        v-model="inventoryItemStock.purchased_date"
                                        v-on="on"
                                    >
                                        <template v-slot:label>
                                            Purchased date
                                            <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    @input="menu = false"
                                    v-model="inventoryItemStock.purchased_date"
                                ></v-date-picker>
                                </v-menu>
                                <span class="text-danger" v-if="$v.inventoryItemStock.purchased_date.$error">This information is required</span>
                            </v-col>
                        </div>
                        

                        <div class="row">
                            <div class="col-12 text-right">
                                <v-btn
                                    class="text-gray btn btn-standard"
                                    depressed
                                    @click="cancel"
                                >Cancel
                                </v-btn>
                                <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createOrUpdate"
                                    :loading="isBusy"
                                    v-if="checkIsAccessible('inventory-item-stock', 'create') || checkIsAccessible('inventory-item-stock', 'edit')"
                                >
                                  {{ inventoryItemStockId ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import InventoryItemService from "@/core/services/inventory-item/InventoryItemService";
import InventoryItemStockService from "@/core/services/inventory-item-stock/InventoryItemStockService";

const inventoryItem = new InventoryItemService();
const inventoryItemStock = new InventoryItemStockService();

export default {
    validations: {
        inventoryItemStock: {
            inventory_item_id: { required },
            qty: {required},
            price: {required},
            file: {},
            purchased_date: {required},
        }
    },
    data() {
        return {
            dialog: false,
            isBusy: false,
            inventoryItemStock: {
                inventory_item_id: null,
                qty: null,
                price: null,
                file: null,
                purchased_date: '',
            },
            errors: [],
            inventoryItems: [],
            menu: false,
            sizeExceed: false,
            receiptImagePath: null,
            receiptImageNotFound: false,
        };
    },
    mounted(){
        this.getInventoryItems();
        if(this.inventoryItemStockId){
            this.getInventoryItemStock();
        }
    },
    computed:{
        inventoryItemStockId(){
            return this.$route.params.inventoryItemStockId;
        }
    },
    methods: {
        cancel(){
            this.$tabs.close();
        },  

        convertToFormData() {
            let formData = new FormData();
            for (let key in this.inventoryItemStock) {
                if (key === "file" && this.inventoryItemStock[key] != null && this.inventoryItemStock[key] != undefined) {
                formData.append('file', this.inventoryItemStock[key]);
                }else {
                if(this.inventoryItemStock[key]){
                    formData.append(key,this.inventoryItemStock[key]);
                }
                }
            }
            return formData;
        },
        
        createOrUpdate() {
            // if(this.receiptImagePath == null){
            //     this.receiptImageNotFound = true;
            // }
            // else{
                // this.receiptImageNotFound = false;
                this.$v.inventoryItemStock.$touch();
                if (this.$v.inventoryItemStock.$error) {
                    setTimeout(() => {
                        this.$v.inventoryItemStock.$reset();
                    }, 3000);
                } else {
                    if (this.inventoryItemStockId) {
                        this.updateInventoryItemStock();
                    } else {
                        this.createInventoryItemStock();
                    }
                }
            // }
        },
        updateInventoryItemStock() {
            this.isBusy = true;

            const formData = this.convertToFormData();

            inventoryItemStock
            .update(this.inventoryItemStock.id, formData)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.$tabs.close();
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createInventoryItemStock() {
            this.isBusy = true;

            const formData = this.convertToFormData();

            inventoryItemStock
            .store(formData)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.$tabs.close();
            })
            .catch(error => {
                this.isBusy = false;
                this.errors = error.errors;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.inventoryItemStock.$reset();
            this.inventoryItemStock = {
                inventory_item_id: null,
                qty: null,
                price: null,
                file: null,
                purchased_date: '',
            };
        },
        getInventoryItemStock(){
            inventoryItemStock
            .show(this.inventoryItemStockId)
            .then((res) => {
                this.inventoryItemStock = res.data.inventoryItemStock;
                if(this.inventoryItemStock.purchased_date){
                    this.inventoryItemStock.purchased_date = this.inventoryItemStock.purchased_date_formatted;
                }
                if(this.inventoryItemStock.receipt_image != null){
                    this.receiptImagePath = this.inventoryItemStock.receipt_image_path['thumb']; 
                }
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        getInventoryItems() {
            inventoryItem
            .all()
            .then(response => {
                this.inventoryItems = response.data.inventoryItems;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        previewReceipt(){
            if(this.inventoryItemStock.file != null){
                this.receiptImagePath= URL.createObjectURL(this.inventoryItemStock.file);
            }else{
                this.receiptImagePath = null;
            }
        },
        deleteReceiptImage(){
            this.receiptImagePath = null;
            this.inventoryItemStock.file = null;
        }
    }
}
</script>
